<template>
  <div class="header-container">
    <h1>{{content.page.title}}</h1>
  </div>
  <section v-html="content.page.mainContent">

  </section>
</template>

<script>

export default{
  name:'Timing',
  props:['initData','contents','pageId'],
  data(){

    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9rem;
  background-image: linear-gradient(to right, rgba(119, 132, 16, .8), rgba(119, 132, 16, .8)), url("@/assets/img/background.png");
  mix-blend-mode: normal;
}

.header-container h1 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
}

section:deep(.timing) {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2.5rem 6.25rem;
}

section:deep(.timing .title) {
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--class-green);
}

section:deep(.timing .date) {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

section:deep(.timing .description) {
  font-weight: 300;
  margin-bottom: 2.5rem;
}

section:deep(.questions) {
  background-color: var(--gray);
  max-width: 1280px;
  margin: 0 auto 7rem auto;
  padding: 2.5rem 6.25rem;
}

section:deep(.questions h3) {
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

section:deep(.questions p) {
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
}

section:deep(.questions a) {
  color: var(--class-green);
  text-decoration: none;
  font-weight: 400;
}
section:deep(table) {
  width: 100%;
  border-collapse: collapse;
}

section:deep(table thead th) {
  border-bottom: 1px solid var(--dark-gray);
}

section:deep(table thead tr th) {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2rem;

  letter-spacing: 1px;
}

section:deep(table tbody td) {
  border-bottom: 1px solid var(--gray);
}

section:deep(table tbody tr td) {
  font-style: normal;
  font-weight: 300;
  line-height: 3rem;
}

@media (max-width: 768px){
  section:deep(.timing) {
    padding: 2.5rem 1rem;
  }
}
</style>